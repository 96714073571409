import strings from "utils/strings";
import TextField from "ui/textfield";
import Modal from 'react-modal';
import {useDispatch, useSelector} from 'react-redux';
import {openDeposit, openDepositAfterLogin, updateCampaignId} from 'store/modals/actions';
import {updateSnackBar} from 'store/common/actions';
import Link from 'next/link';
import {useRouter} from 'next/router';
import useAuth from 'hooks/auth';
import {useEffect, useState, useRef} from 'react';
import clsx from 'clsx';
import {getDocumentValidation, getPaymentInfo, getPaymentMethods, getProfile} from "services/account";
import Selector from 'ui/depositSelectDropDown/index';
import PaymentMethod from "interfaces/paymentMethod";
import Offer from "interfaces/offer";
import Payment from "utils/payment";
import CircularProgress from '@mui/material/CircularProgress';
import DepositIframe from "./iframe";
import InfoIcon from 'themes/spins/svg/info'
import Button from "@/template/small_ui/button";
import CMS from "utils/cms";
import {getAuthToken} from "utils/cookies";
import {updateSpinStatus} from "store/account/actions";
import Close from 'themes/spins/svg/close'
import {useTheme} from "next-themes";
import { useMediaQuery } from 'react-responsive';
import useCommon from "@/AbsoluteComponents/hooks/common";
import ArrowRight from "@/template/svg/arrow-right";


function DepositModal() {
    const dispatch = useDispatch();
    const {resolvedTheme, setTheme, theme, systemTheme} = useTheme();
    const auth = useAuth();
    const router = useRouter();
    const open = useSelector((state: any) => state.modals.openDeposit);
    const campaignId = useSelector((state: any) => state.modals.campaignId);
    const [applying, setApplying] = useState<boolean>(false);
    const [loading, setLoading] = useState<Boolean>(false);
    const [methods, setMethods] = useState<Array<PaymentMethod>>([]);
    const [offers, setOffers] = useState<Array<Offer>>([]);
    const [amount, setAmount] = useState(0);
    const [redirectUrl, setRedirectUrl] = useState('');
    const [qrCode, setQrCode] = useState('');
    const [depositCount, setDepositCount] = useState<number>(0)
    const [selectedMethod, setSelectedMethod] = useState<PaymentMethod | null>(null);
    const [selectedOffer, setSelectedOffer] = useState<Offer | null>(null);
    const [cash, setCash] = useState(0);
    
    
    const isMedia1 = useMediaQuery({
        query: '(max-width: 550px)'
    });
    const common_hooks = useCommon()

    const _checkProfile = async () => {
        const data = await getProfile(getAuthToken());

        // @ts-ignore
        if (typeof window!.profileIntervalCount === "undefined") window!.profileIntervalCount = 0;

        if (data.response && data.response['email']) {
            dispatch(updateSpinStatus(data.response.attributes));

            // @ts-ignore
            if (window!.profileIntervalCount < 1) {

                // @ts-ignore
                window!.profileIntervalCount += 1;

                setTimeout(_checkProfile, 2000);
            } else {
                // @ts-ignore
                window!.profileIntervalCount = undefined;
            }
        }
    }

    const onSubmit = async (e: any) => {
        e.preventDefault();

        const isCnp: boolean = selectedMethod?.methodType === 'cnp';

        //added
        // const isDebitCard:boolean = selectedMethod?.methodType === 'debit_card';
        //added

        // const triedToOpenDeposit:boolean = useSelector((state: any) => state.modals.depositAfterLogin);

        if (!isCnp && amount < getMinAmount()) {
            dispatch(updateSnackBar(`${strings['depunere_minima']} ${getMinAmount()} RON.`, 'error'));
            return;
        }

        if (!isCnp && amount > getMaxAmount()) {
            dispatch(updateSnackBar(`${strings['limita_de_depunere']} ${getMaxAmount()} RON.`, 'error'));
            return;
        }

        const form = new FormData(e.target);

        setApplying(true);
        const data = await getPaymentInfo(
            selectedMethod?.methodType || '',
            selectedMethod?.method || '',
            amount,
            form.get('bonus')?.toString() || '',

        )
        setApplying(false);

        if (data.error) {
            dispatch(updateSnackBar(data.message || 'Eroare necunoscută', 'error'));
        } else if (data.response?.success) {
            if (data.response.redirectUrl && data.response.redirectUrl.length) setRedirectUrl(data.response.redirectUrl);
            if (data.response.qrCode && data.response.qrCode.length) setQrCode(data.response.qrCode);

            //added

            if (data.response.clientSecret && data.response.clientSecret.length) {

                //@ts-ignore
                const pay = window.Pay.com({identifier: "{merchant_identifer}"});

                const checkout = await pay.checkout({token: data.response.clientSecret});
                
                if(selectedMethod?.methodType === "debit_card") {
                    const styles = {
                        background: '#fff',
                        padding: '10px',
                        borderRadius: '10px',
                        // minHeight: "300px",
                        margin: "0 8px"
                        
                    }
                    
                    await checkout.universal({
                        container: "#card_frame",
                        style: {
                            base: styles
                        }
                        
                    });
                    setRedirectUrl('return null')
                    
                } else {
                    
                    const baseStyles = {
                        margin: "0 8px"
                    }
                    
                    await checkout.universal({
                        container: "#card_frame",
                        style: {
                            base: baseStyles,
                            modalForm: {
                                base:baseStyles
                            }
                            
                        }
                    });
                }
                
                // @ts-ignore
                checkout.on(checkout.EVENT_TYPES.PAYMENT_SUCCESS, data => {
                    successHandle()
                })
                
            }
            //added

            //Send info to google and FB

            let type

            if (depositCount > 0) {
                type = 'first deposit'
            } else {
                type = 'deposit'
            }

            const info = [
                {user: auth.getUser()},
                {method: selectedMethod?.method},
                {methodName: selectedMethod?.methodName},
                {amount: amount},
                {offerName: selectedOffer?.title}
            ]

            CMS.gtagTrackDeposit(type, {info})
            CMS.fbTrackDeposit(type, {info})

            //Send info to google and FB end
        } else {
            dispatch(updateSnackBar(`${strings['eroare_necunoscuta']}`, 'error'));
        }
    };

    let launcher = typeof window !== 'undefined' ? document.querySelector('#launcher') :  null;
    
    const fetchDepositInformation = async () => {
        setLoading(true);

        if(window.innerWidth < 400) {
            launcher?.classList.add('hideLauncher')
        }
        
        const data = await getPaymentMethods();
        
        let depositLimitAmount: number | undefined;
        
        if (Array.isArray(data.response?.methods)) {
            setDepositCount(Number(data.response.totalDepositCount) || 0)

            const methods: Array<PaymentMethod> = [];

            const card = data.response.methods.find((json: any) => json['methodType'] === 'credit_card');
            const pay_safe = data.response.methods.find((json: any) => json['methodType'] === 'pay_safe_card');
            const cnp = data.response.methods.find((json: any) => json['methodType'] === 'cnp');
            //ADDED
            const debitCard = data.response.methods.find((json: any) => json['methodType'] === 'debit_card');
            const bank_transfer = data.response.methods.find((json:any) => json['methodType'] === 'bank_transfer');
            const voucher = data.response.methods.find((json:any) => json['methodType'] === 'voucher');

            if (card) {
                methods.push(Payment.paymentMethodFactory(card))
            }
            
            if (debitCard) {
                methods.push(Payment.paymentMethodFactory(debitCard));
            }
            
            if (pay_safe) {
                methods.push(Payment.paymentMethodFactory(pay_safe))
            }
            
            if (cnp) {
                methods.push(Payment.paymentMethodFactory(cnp))
            }
            
            if (bank_transfer) methods.push(Payment.paymentMethodFactory(bank_transfer));
            
            if (voucher) methods.push(Payment.paymentMethodFactory(voucher));
            
            // console.log(methods)

            setMethods(methods);
            setSelectedMethod(methods[0] || null);
            //added for test
            // setSelectedMethod(debitCard)

            if (data.response && Object.keys(data.response).includes('depositLimitAmount')) {
                depositLimitAmount = Number(data.response.depositLimitAmount);
            }
        }

        if (Array.isArray(data.response?.campaigns)) {
            const campaigns: Array<Offer> = data.response.campaigns.map((json: any) => Payment.offerFactory(json));
            setOffers(campaigns);
        }

        const documentValidation = await getDocumentValidation();
        let approved: boolean = false;

        if (Array.isArray(documentValidation.response)) {
            approved = documentValidation.response.find((item: any) => (item.type === 'identification_card' && item.status === 'approved')) !== undefined;
        }

        if (!approved) {
            if (depositLimitAmount === 0) {
                dispatch(updateSnackBar(`${strings['ai_atins_limita_de_depuneri']}  <b>900 RON</b>. ${strings['trebuie_sa']} <a href="/account/account-validation" title="Verificarea contului">${strings['validezi_contul']}</a> ${strings['pentru_depunere']}.`, 'error'));
                dispatch(openDeposit(false));
            } else if (depositLimitAmount && depositLimitAmount > 0) {
                dispatch(updateSnackBar(`${strings['mai_poti_depune']} <b>${depositLimitAmount} RON</b> ${strings['fara_validare']}. ${strings['incarca_dovada_document']} <a href="/account/account-validation" title="Verificarea contului">${strings['aici']}</a> ${strings['pentru_eliminare_depunerei']}.`, 'error'));
            }
        }

        setLoading(false);
    }
    
    const getFilteredOffers = ():Array<Offer> => {
        
        let filtered = offers.filter((off:Offer) => off.methods.includes(selectedMethod?.method)).sort((a:any, b:any) => a.minDepositAmount - b.minDepositAmount)
        
        return filtered
    }

    const getMinAmount = (): number => {
        const minAmount: number = selectedMethod?.minAmount || 0;

        if (selectedOffer && selectedOffer.minDepositAmount > 0) {
            return selectedOffer.minDepositAmount;
        }

        return minAmount;
    }

    const getMaxAmount = (): number => {
        return selectedMethod?.maxAmount || 0;
    }

    const getAmountWithTax = (): number => {
        if (amount > 0 && selectedMethod/*&& selectedMethod?.feeInclude*/) {
            if (selectedMethod.feeType === 'percentage') {
                const percentage = selectedMethod?.feeValue / 100;

                if (percentage > 0) {
                    if (selectedMethod.methodType !== 'credit_card' && selectedMethod.methodType !== 'debit_card') {
                        return Number((amount - (amount * percentage)).toFixed(2));
                    } else {
                        return Number((amount + (amount * percentage)).toFixed(2));
                    }
                }
            } else {
                return amount + selectedMethod?.feeValue
            }
        }

        return amount;
    };

    const getTaxAmount = (): number => {
        if (amount > 0 && selectedMethod /*&& selectedMethod?.feeInclude*/) {
            if (selectedMethod.feeType === 'percentage') {
                const percentage = selectedMethod?.feeValue / 100;

                if (percentage > 0) return (amount * percentage);
            } else {
                return selectedMethod?.feeValue
            }
        }

        return 0;
    };

    const emailProps = {
        name: 'amount',
        value: amount || '',
        onChange: (e: any) => {
            setAmount(parseInt(e.target.value));
        }
    };

    const successHandle = () => {
        dispatch(updateSnackBar('Depunerea a fost completată cu succes', 'success'));
        dispatch(openDeposit(false));
    }

    useEffect(() => {
        dispatch(openDeposit(false));
    }, [router.pathname]);
    
    useEffect(() => {
        setCash(auth.getCash() + amount);
    
    }, [])
    
    useEffect(() => {
        if (cash > 0 && auth.getCash() >= cash && !redirectUrl) {
            setTimeout(() => {
                successHandle()
            }, 1000);
        }
    }, [auth.getCash()]);
    
    useEffect(() => {
        if(!getFilteredOffers().length) {
            setSelectedOffer(null)
        }
    }, [getFilteredOffers()]);
    
    // useEffect(() => {
    //     if(Array.isArray(methods) && methods.length && campaignId.length && Array.isArray(offers) && offers.length) {
    //
    //         let offer = offers.find((off) => off.campaignId === campaignId)
    //
    //         let method = methods.find((m) => offer?.methods.includes(m.method))
    //
    //         if(method) {
    //             setSelectedMethod(method)
    //         }
    //     }
    // }, [campaignId, methods, offers]);

    const scriptRef = useRef(null);

    const isCard: boolean = selectedMethod?.methodType === 'credit_card' || selectedMethod?.methodType === 'debit_card';

    const fromAmount: number = !isCard ? Number(amount.toFixed(2)) : Number(getAmountWithTax().toFixed(2));
    const toAmount: number = !isCard ? Number(getAmountWithTax().toFixed(2)) : Number(amount.toFixed(2));
    
    return (
        <Modal
            onAfterOpen={() => {
                fetchDepositInformation();
                common_hooks.hideZendesk(true)
            }}
            style={{
                overlay: isMedia1 ? {
                    flexDirection: "column",
                    justifyContent: "flex-end"
                } : {}
            }}
            onAfterClose={() => {
                setAmount(0);
                common_hooks.hideZendesk(false)
                setRedirectUrl('');
                setQrCode('');
                setSelectedMethod(null);
                dispatch(openDepositAfterLogin(false));
                dispatch(updateCampaignId(''))
            }}
            ariaHideApp={false}
            className={clsx(redirectUrl.length ? 'ReactModal__Deposit' : 'ReactModal__SM', 'depositModal')}
            isOpen={open}
            onRequestClose={() => {
                dispatch(openDeposit(false));
                const root = document.getElementsByTagName('html')[0];
                root.removeAttribute('style');
            }}
            contentLabel="Example Modal"
        >

            <div className={clsx('ReactModal__Inner', 'theme_box_bg_unshaded4')}>

                <div className={clsx("ReactModal__Inner__Header", 'depositModal__header')}>

                    <h2 className={clsx("ReactModal__Title", 'theme_headings')}>{strings['deposition']}</h2>
                    <button
                        onClick={() => {
                            dispatch(openDeposit(false))
                            const root = document.getElementsByTagName('html')[0];
                            root.removeAttribute('style');
                        }}
                        aria-label={"clase"}
                        className={'ReactModal__Close'}
                    >
                        <Close/>
                    </button>
                </div>

                <div className="ReactModal__Inner__Content">

                    {(selectedMethod && selectedMethod?.methodType !== 'cnp' && (getMaxAmount() > 0 && getMaxAmount() < 50000) && !loading) &&
                        <div className="deposit_warning">
                            <InfoIcon/>
                            <span>{strings['suma_depusa_disponibila']} {getMaxAmount()} RON {strings['datorita_limitei_acumulate']}. {strings['access']} <Link
                                href={'/account/responsable-game'}> {strings['acest_link']} </Link> {strings['info_limita_ta']}.</span>
                        </div>
                    }

                    <div style={{paddingBottom: "8px"}} id={'card_frame'}/>
                    <div  id={'card_frame1'}/>

                    {redirectUrl.length > 0 &&
                        <DepositIframe
                            amount={toAmount}
                            url={ /*+ "&theme_id=247276"*/ redirectUrl}
                            onSuccess={() => {
                                if (amount >= 100) {
                                    setTimeout(_checkProfile, 2000)
                                }
                                successHandle();
                            }}
                        />
                    }

                    {qrCode.length > 0 && <div style={{paddingBottom: "20px"}} className={'mb-30'}><img src={qrCode} alt="QR CODE"/></div>}

                    {qrCode.length > 0 && (
                        <Button
                            onClick={() => {
                                dispatch(openDeposit(false))
                                const root = document.getElementsByTagName('html')[0];
                                root.removeAttribute('style');
                            }}
                            type={'button'} className={'logInModal__btn'}
                            text={strings['close']}
                            style={{marginBottom: '32px'}}
                        />
                    )}

                    {(redirectUrl.length === 0 && qrCode.length === 0) && (
                        <>
                            {loading ? (
                                <div style={{padding: 50, textAlign: 'center'}}>
                                    <CircularProgress/>
                                </div>
                            ) : (
                                <>
                                    <form onSubmit={onSubmit}>
                                        <div className={clsx('deposit_form', 'header_bordered')}>
                                            <div style={{marginBottom: 16}}>
                                                <Selector
                                                    name={'deposit_method'}
                                                    defaultValue={selectedMethod?.method}
                                                    label={strings['deposit_method']}

                                                    options={methods.map((method: PaymentMethod) => ({
                                                        key: method.method,
                                                        label: method.methodName,
                                                        icon: method.methodIcon,
                                                        subLabel:(theme === 'light'? method.methodIconsPaymentTheme : method.methodIconsPayment),
                                                    }))}
                                                    onChange={(key) => {
                                                        setSelectedMethod(methods?.find((m) => m.method === key) || null);
                                                        setAmount(0);
                                                    }}
                                                />
                                            </div>

                                            {selectedMethod?.methodType !== 'cnp' &&
                                                <div style={{marginBottom: '-8px'}}>
                                                    <TextField label={strings['adauga_fonduri']} type='text'
                                                               placeholder={'Adaugă fonduri'} {...emailProps}/>
                                                </div>
                                            }

                                            {(selectedMethod && selectedMethod.amountList.length > 0 && selectedMethod?.methodType !== 'cnp') &&
                                                <div className="mb-30">
                                                    <div className="deposit-amount-list">
                                                        {selectedMethod.amountList.map(a => {
                                                            return (
                                                                <div className="deposit-amount-list__col"
                                                                     key={`${selectedMethod.methodType}-${a}`}>
                                                                    <button
                                                                        // className={clsx('deposit-amount-list__btn',  'theme_searchInput_header', amount === a && 'deposit-amount-list__btn--active')}
                                                                        className={clsx('deposit-amount-list__btn',  'theme_searchInput_header', amount === a && 'themes_deposit_wrapper')}
                                                                        type={'button'}
                                                                        onClick={() => setAmount(a)}
                                                                    >
                                                                        <span className={'theme_textColor_secondary'}>
                                                                            {a} RON
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        {getFilteredOffers().length ? (
                                            <div className={'depositBonusBlock'}>
                                                <Selector
                                                    className={clsx((selectedOffer !== null) && "themes_deposit_wrapper")}
                                                    position
                                                    bonusItem
                                                    defaultValue={campaignId}
                                                    name={'bonus'}
                                                    label={strings['choose_bonus']}
                                                    options={[
                                                        {key: '', label: strings['withoutBonus']},
                                                        ...getFilteredOffers().map((offer: Offer) => ({
                                                            key: offer.campaignId,
                                                            label: offer.title
                                                        }))
                                                    ]}
                                                    bgStyle
                                                    onChange={(key) => {
                                                        setSelectedOffer(getFilteredOffers()?.find((m) => m.campaignId === key) || null)
                                                    }}
                                                />
                                            </div>
                                        ) : null}

                                        <div style={{marginBottom: 16}}>
                                            <Button
                                                deposit
                                                loading={applying}
                                                type={'submit'}
                                                text={strings['depune']}
                                            />
                                        </div>


                                    </form>

                                    {(amount > 0 && selectedMethod?.methodType !== 'cnp') &&
                                        <div className={clsx("deposit_overview", 'theme_bg_dropDown1')}>
                                            <div className="deposit_overview__amount">
                                                <div
                                                    className={clsx("deposit_overview__amount__type", 'theme_textColor_secondary')}>{strings['haveToPay']}</div>
                                                <span className={'theme_textColor_secondary'}>{fromAmount} RON</span>
                                            </div>

                                            <div className={"circle theme_white_dark_text"}>
                                                <ArrowRight />
                                            </div>

                                            <div className="deposit_overview__amount">
                                                <div
                                                    className={clsx("deposit_overview__amount__type", 'theme_textColor_secondary')}>{strings['willGet']}</div>
                                                <span className={'theme_textColor_secondary'}>{toAmount} RON</span>
                                            </div>
                                        </div>
                                    }

                                        <div className={clsx('tax-notice', 'theme__headerContainer__list')}>
                                            {/*<InfoIcon/>*/}
                                            {/*<span>Taxa de {getTaxAmount()} RON este adăugat la depunerea ta.</span>*/}
                                            {getTaxAmount() > 0 &&
                                                `Taxa de ${getTaxAmount()} RON este inclusă în depunerea ta.`
                                            }
                                        </div>

                                </>
                            )}
                        </>
                    )}
                </div>

            </div>
            
        </Modal>
    )
}

export default DepositModal