import strings from "utils/strings";
import TextField from "ui/textfield";
import Modal from 'react-modal';
import {useDispatch, useSelector} from 'react-redux';
import { updateSnackBar} from 'store/common/actions';
import useAuth from 'hooks/auth';
import {useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import {applyWithdraw, getWithdrawMethods} from "services/account";
import Selector from 'ui/depositSelectDropDown/index';
import PaymentMethod from "interfaces/paymentMethod";
import Payment from "utils/payment";
import ArrowRight from "themes/default/svg/arrow-right";
import CircularProgress from '@mui/material/CircularProgress';
import PaymentMethodStoredData from "interfaces/paymentMethodStoredData";
import Button from "themes/spins/small_ui/button";
import InfoIcon from "themes/default/svg/info";
import ModalLayout from "themes/spins/ui/modal_layout";
// import styles from "@/template/styles/modals.module.scss";
import styles from "themes/spins/styles/modals.module.scss";
import Checked from "@/template/svg/checked";


const WithdrawModal = () => {
    const dispatch = useDispatch();
    const auth = useAuth();
    const open = useSelector((state:any) => state.modals.openWithdraw);
    const check = useRef<any>(null)
    const [loading, setLoading] = useState<boolean>(false);
    const [applying, setApplying] = useState<boolean>(false);
    const [methods, setMethods] = useState<Array<PaymentMethod>>([]);
    const [amount, setAmount] = useState(0);
    const [selectedMethod, setSelectedMethod] = useState<PaymentMethod | null>(null);
    const [showAllMethods, setShowAllMethods] = useState<boolean>(false)
    const [cardReference, setReference] = useState<string>('')

    const onSubmit = async (e:any) => {
        e.preventDefault();

        const isCnp:boolean = selectedMethod?.methodType === 'cnp';

        if (!isCnp && amount < getMinAmount()) {
            dispatch(updateSnackBar(`Retragere minimă ${getMinAmount()} RON.`, 'error', false));
            return;
        }

        if (!isCnp && amount > getMaxAmount()) {
            dispatch(updateSnackBar(`Limita de retragere ${getMaxAmount()} RON.`, 'error', false));
            return;
        }

        let message = strings['limita_maxima_retragere'];
        let replacedMsg = strings['limita_maxima_retragereRO'];

        const form = new FormData(e.target);

        // const reference = form.get('method_stored_data');
        const reference = cardReference;

        setApplying(true);

        const data = await applyWithdraw(
            selectedMethod?.method || '',
            amount || 0,
            reference?.toString() || ''
        );

        // reference?.toString() - 025ddac2-515f-46a3-85ad-581dd18e582a

        setApplying(false);

        if (data.error && data.message) {

            if (data.message.search(message) > -1){
                message?.replace(message,replacedMsg )
            } else {
                if (data.message) {
                    dispatch(updateSnackBar(strings[data.message] || data.message, 'error', false));
                } else {
                    dispatch(updateSnackBar(`${strings['eroare_necunoscuta']}`, 'error', false));
                }
            }

        } else if (data.response?.success) {

            dispatch(updateSnackBar(`${strings['retragere_cu_success']}`, 'success', false));
            auth.closeWithdrawPopup();

        } else {

            dispatch(updateSnackBar(`${strings['eroare_necunoscuta']}`, 'error', false));

        }
    };
    let launcher = typeof window !== 'undefined' ? document.querySelector('#launcher') :  null;

    const fetchInformation = async () => {
        setLoading(true);

        
        launcher?.classList.add('hideLauncher')

        const data = await getWithdrawMethods();

        if (Array.isArray(data.response?.methods)) {
            const methods:Array<PaymentMethod> = [];

            const card = data.response.methods.find((json:any) => json['methodType'] === 'credit_card');
            const pay_safe = data.response.methods.find((json:any) => json['methodType'] === 'pay_safe_card');
            const cnp = data.response.methods.find((json:any) => json['methodType'] === 'cnp');
            const debitCard = data.response.methods.find((json:any) => json['methodType'] === 'debit_card');
            const bank_transfer = data.response.methods.find((json:any) => json['methodType'] === 'bank_transfer');

            if (debitCard) methods.push(Payment.paymentMethodFactory(debitCard));
            if (card) methods.push(Payment.paymentMethodFactory(card));
            if (pay_safe) methods.push(Payment.paymentMethodFactory(pay_safe));
            if (cnp) methods.push(Payment.paymentMethodFactory(cnp));
            if (bank_transfer) methods.push(Payment.paymentMethodFactory(bank_transfer));

            setMethods(methods);
            setSelectedMethod(methods[0] || null);
            
        }

        setLoading(false);
    }

    const getMinAmount = ():number  => {
        return selectedMethod?.minAmount || 0;
    }

    const getMaxAmount = ():number => {
        return selectedMethod?.maxAmount || 0;
    }

    const emailProps = {
        name: 'amount',
        value: amount === 0 ? '' : amount,
        onChange: (e:any) => {
            setAmount(parseInt(e.target.value) || 0);
        }
    };

    const getAmountWithTax = ():number => {
        if (amount > 0 && selectedMethod /*&& selectedMethod?.feeInclude*/) {
            if (selectedMethod.feeType === 'percentage') {
                const percentage = selectedMethod?.feeValue / 100;

                if (percentage > 0) return amount - (amount * percentage);
            } else {
                return amount + selectedMethod?.feeValue
            }
        }

        return amount;
    };

    const getTaxAmount = ():number => {
        if (amount > 0 && selectedMethod /*&& selectedMethod?.feeInclude*/) {
            if (selectedMethod.feeType === 'percentage') {
                const percentage = selectedMethod?.feeValue / 100;

                if (percentage > 0) return Number((amount * percentage).toFixed(2));
            } else {
                return selectedMethod?.feeValue
            }
        }

        return 0;
    };

    const testArray = [
        {
            "reference": "025ddac2-515f-46a3-85ad-581dd18e582a",
            "creditCardNum": "4****1713",
            "description": "Visa:4****1713",
            "paymentMethodId": "2573325148",
            "cardEnding": "1713",
            "methodIcon": ""
        },
        {
            "reference": "025ddac2-515f-46a3-85ad-581dd18e582a",
            "creditCardNum": "4****1713",
            "description": "Visa:4****1713",
            "paymentMethodId": "2573325148",
            "cardEnding": "1713",
            "methodIcon": ""
        },
        {
            "reference": "025ddac2-515f-46a3-85ad-581dd18e582a",
            "creditCardNum": "4****1713",
            "description": "Visa:4****1713",
            "paymentMethodId": "2573325148",
            "cardEnding": "1713",
            "methodIcon": ""
        },
        {
            "reference": "025ddac2-515f-46a3-85ad-581dd18e582a",
            "creditCardNum": "4****1713",
            "description": "Visa:4****1713",
            "paymentMethodId": "2573325148",
            "cardEnding": "1713",
            "methodIcon": ""
        },
        {
            "reference": "025ddac2-515f-46a3-85ad-581dd18e582a",
            "creditCardNum": "4****1713",
            "description": "Visa:4****1713",
            "paymentMethodId": "2573325148",
            "cardEnding": "1713",
            "methodIcon": ""
        },
        {
            "reference": "025ddac2-515f-46a3-85ad-581dd18e582a",
            "creditCardNum": "4****1713",
            "description": "Visa:4****1713",
            "paymentMethodId": "2573325148",
            "cardEnding": "1713",
            "methodIcon": ""
        },
        {
            "reference": "025ddac2-515f-46a3-85ad-581dd18e582a",
            "creditCardNum": "4****1713",
            "description": "Visa:4****1713",
            "paymentMethodId": "2573325148",
            "cardEnding": "1713",
            "methodIcon": ""
        },
    ]

    const methodsToShow = ():Array<PaymentMethodStoredData> => {
        let arr : Array<PaymentMethodStoredData> = []
        let limit: number = 5
        let originalArray:Array<PaymentMethodStoredData> = selectedMethod?.storedData || []
        // let originalArray:Array<PaymentMethodStoredData> = testArray || []

        if(selectedMethod?.storedData.length) {
            if (!showAllMethods && originalArray?.length > limit) {
                arr = originalArray.slice(0, limit-1);
            } else if(showAllMethods && originalArray?.length > limit) {
                arr = originalArray
            } else {
                arr = originalArray
            }
        }

        return arr
    }

    useEffect(() => {

        if(open && (auth.getFreeBonuses() > 0 || auth.getBonus() > 0 || auth.getFreeSpins() > 0) ) {

            const bonusesStr:Array<string> = [];

            if(auth.getBonus() > 0) bonusesStr.push(`${auth.getBonus()} RON`);

            if (auth.getFreeSpins() > 0) {
                bonusesStr.push(`${auth.getFreeSpins()} ${strings['runde_gratuite']}`);
            }

            if (auth.getFreeBonuses() > 0) {
                bonusesStr.push(`${auth.getFreeBonuses()} ${strings['spaciala_gratuita']}`);
            }

            dispatch(updateSnackBar(`${strings['retragerea_balantei']} ${bonusesStr.join(', ')} ${strings['se_anuleaza']}`, 'info', false));

            dispatch(updateSnackBar(`${strings['retragere_din_balanta']}.`, 'warning', false));

        } else {

        }

    }, [open]);
    
    useEffect(() => {
        const selectDefault = () => {
            const debit_card:boolean = selectedMethod?.methodType === 'debit_card';
            const bank_transfer:boolean = selectedMethod?.methodType === 'bank_transfer';
            if(typeof selectedMethod === "object" && selectedMethod?.storedData.length && (debit_card || bank_transfer)) {
                setReference(methodsToShow()[0].reference)
            }
        }
        
        selectDefault()
        
    }, [selectedMethod]);

    const commonStyle = {
        marginBottom: '20px'
    }
    

    return (
        <Modal
            onAfterOpen={() => {
                fetchInformation();
            }}
            onAfterClose={() => {
                setAmount(0);
                setSelectedMethod(null);
            }}
            ariaHideApp={false}
            className={clsx('ReactModal__SM', 'theme_box_bg_shaded3', 'modalRetragere' ,styles.withdraw_modal)}
            isOpen={open}
            onRequestClose={() => auth.closeWithdrawPopup()}
            contentLabel="Example Modal"
        >

            <ModalLayout withPadding={false} title={strings['retragere']} onClose={() => auth.closeWithdrawPopup()}>

                <div style={{paddingBottom: '15px', marginBottom: '-5px', }} className="ReactModal__Inner__Content">

                    {loading ? (
                        <div style={{padding: 50, textAlign: 'center'}}>
                            <CircularProgress/>
                        </div>
                    ) : (
                        <>
                            <form className={styles.withdraw_modal__form} onSubmit={onSubmit}>

                                <div style={commonStyle} className="mb-20">
                                    <Selector
                                        name={'method'}
                                        defaultValue={selectedMethod?.method}
                                        label={strings['deposit_method']}
                                        options={methods.map((method:PaymentMethod) => ({key: method.method, label: method.methodName, icon: method.methodIcon}))}
                                        onChange={(key) => setSelectedMethod(methods?.find((m) => m.method === key) || null)}
                                    />
                                </div>

                                {selectedMethod &&
                                    <div style={commonStyle} className="mb-30">
                                        
                                        <div className={styles.withdraw_modal__check_boxes}>

                                            {selectedMethod.storedData.length ? methodsToShow().map((method:PaymentMethodStoredData, i:number) => {
                                                return (
                                                    <div
                                                        key={'method_Card' + i}
                                                        style={{marginRight: 10}}
                                                        className={clsx(styles.box)}
                                                    >
                                                        <label
                                                            className={clsx(
                                                                styles.checkmark_label,
                                                                styles.checkmark_labelText,
                                                            )}
                                                        >
                                                            <input
                                                                checked={cardReference === method.reference}
                                                                type="radio"
                                                                name={'name'}
                                                                style={{opacity: '0 !important'}}
                                                                onChange={(e) => {
                                                                    setReference(method.reference)
                                                                }}
                                                            />

                                                            <span className={clsx(styles.checkmark)}>
                                                                <Checked />
                                                            </span>

                                                            <span className={clsx('theme_text_mainColor', cardReference === method.reference && 'theme_white_dark_text')}>
                                                                *{selectedMethod?.methodType === 'bank_transfer' ? method.phoneNumber : method.cardEnding}
                                                            </span>

                                                        </label>
                                                    </div>
                                                )}
                                            ) : null}

                                        </div>


                                        {selectedMethod.storedData.length > 5 && (
                                            <div onClick={() => {
                                                setShowAllMethods(!showAllMethods)
                                            }}>
                                                show
                                            </div>
                                        )}

                                    </div>
                                }

                                <div style={commonStyle} className="mb-20">
                                    <TextField label={strings['retragere_bani']} type='text' placeholder={'Adaugă fonduri'} {...emailProps}/>
                                    {(amount > 0 && amount > auth.getCash()) && <p className="errorMsg">{strings['max_retragere']} {auth.getCash()} RON.</p>}
                                </div>

                                <Button
                                    success
                                    loading={applying}
                                    type={'submit'}
                                    disabled={(amount > 0 && amount > auth.getCash())}
                                    text={strings['retragere']}
                                />

                            </form>

                            {getAmountWithTax() > 0 &&
                                <div style={{border: 'none !important'}} className={clsx("deposit_overview", "theme_bg_dropDown1")}>

                                    <div className={clsx("deposit_overview__amount")}>
                                        <div className={clsx("deposit_overview__amount__type", "theme_text_mainColor")}>{strings['retragere']}</div>

                                        <span className={"theme_white_dark_text"}>
                                            {amount.toFixed(2)} RON
                                        </span>
                                    </div>

                                    <div className={clsx("deposit_overview__arrow", "theme_white_dark_text", 'theme_overview_bg')}>
                                        {/*<ArrowRight/>*/}
                                    </div>

                                    <div className={clsx("deposit_overview__amount")}>
                                        <div className={clsx("deposit_overview__amount__type", "theme_text_mainColor")}>{strings['willGet']}</div>

                                        <span className={"theme_white_dark_text"}>
                                            {getAmountWithTax().toFixed(2)} RON
                                        </span>
                                    </div>

                                </div>
                            }
                        </>
                    )}

                    {getTaxAmount() > 0 &&
                        <div className={'tax-notice'}>
                            {/*<InfoIcon/>*/}
                            {/*<div>Va fi aplicată o taxă de {getTaxAmount()} RON.</div>*/}
                            <div>{strings['tax_paid']} {getTaxAmount()} RON.</div>
                        </div>
                    }

                </div>
            </ModalLayout>

        </Modal>
    )
}

export default WithdrawModal;